<template>
  <b-modal
    centered
    size="lg"
    id="modal_task_detail"
    hide-footer
    body-class="modal-fixed-height-50"
  >
    <template #modal-title>
      <div class="">
        <div>Görev Detayları #{{sf.task.task_id}}</div>
      </div>
    </template>
    <div class="modalbody p-0">
      <div>
        <b-tabs nav-class="text-uppercase" justified>
          <b-tab title="Görev Bilgileri">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Görev No</small
                  >
                  <input
                    v-model="sf.task.task_id"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Görev Türü</small
                  >
                  <input
                    :value="getTaskType"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Durum</small
                  >
                  <status-badge
                    :status="sf.task.status_item"
                    class="mt-05rem"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <small class="text-sm text-uppercase font-semibold"
                >Görev Tanımı</small
              >
              <input
                :value="sf.task.task_name"
                type="text"
                class="form-control"
                readonly
              />
            </div>
            <div class="form-group">
              <small class="text-sm text-uppercase font-semibold">Notlar</small>
              <textarea
                :value="sf.task.task_notes"
                class="form-control"
                readonly
                rows="4"
              ></textarea>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Planlanan Tarih</small
                  >
                  <input
                    :value="convertDate(sf.task.planned_date)"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >İlgili Personel</small
                  >
                  <input
                    :value="
                      sf.task.staff_item != null
                        ? sf.task.staff_item.user_namesurname
                        : null
                    "
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div v-if="sf.task.status == 'complated'" class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Tamamlanan Tarih</small
                  >
                  <input
                    :value="convertDate(sf.task.complated_date)"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Tamamlayan Personel</small
                  >
                  <input
                    :value="
                      sf.task.staff_item_complated != null
                        ? sf.task.staff_item_complated.user_namesurname
                        : null
                    "
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div v-if="sf.task.status == 'canceled'" class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >İptal Tarihi</small
                  >
                  <input
                    :value="convertDate(sf.task.canceled_date)"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >İptal Notu</small
                  >
                  <textarea
                    :value="sf.task.task_cancel_notes"
                    class="form-control"
                    readonly
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Öncelik</small
                  >
                  <input
                    :value="sf.task.priority_text"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="sf.device.device_id > 0" title="Cihaz Bilgileri">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Cihaz Kodu</small
                  >
                  <input
                    :value="sf.device.device_id"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Barkod</small
                  >
                  <input
                    :value="sf.device.device_barcode"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Seri No</small
                  >
                  <input
                    :value="sf.device.device_serial_number"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Cihaz Türü</small
                  >
                  <input
                    :value="sf.device.device_type_text"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Marka</small
                  >
                  <input
                    :value="sf.device.brand_name"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Model</small
                  >
                  <input
                    :value="sf.device.model_name"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div v-if="sf.device.due_date" class="col-sm-6">
                <div class="form-group">
                  <small
                    class="text-sm text-uppercase font-semibold text-primary"
                    >Acil Çıkış Gerekiyor</small
                  >
                  <input
                    :value="sf.device.is_immediate == 1 ? 'Evet' : 'Hayır'"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div v-if="sf.device.due_date" class="col-sm-6">
                <div class="form-group">
                  <small
                    class="text-sm text-uppercase font-semibold text-danger"
                    >Çıkması Gereken Tarih</small
                  >
                  <input
                    :value="convertDate(sf.device.due_date)"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="sf.delivery.d_id > 0" title="Teslimat Bilgileri">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small
                    class="text-sm text-uppercase font-semibold text-primary"
                    >Teslimat Türü</small
                  >
                  <input
                    :value="delivery_type"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >Teslimat No</small
                  >
                  <input
                    :value="sf.delivery.d_id"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold">İl</small>
                  <input
                    :value="sf.delivery.city_text"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <small class="text-sm text-uppercase font-semibold"
                    >İlçe</small
                  >
                  <input
                    :value="sf.delivery.district_text"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <small class="text-sm text-uppercase font-semibold">Adres</small>
              <textarea
                :value="sf.delivery.delivery_address"
                class="form-control"
                readonly
              ></textarea>
            </div>
            <div class="form-group">
              <small class="text-sm text-uppercase font-semibold">Konum</small>
              <div class="input-group">
                <input
                  :value="sf.delivery.location"
                  type="text"
                  class="form-control"
                  readonly
                />
                <div class="input-group-append">
                  <button
                    v-on:click="openLocation()"
                    class="btn btn-outline-secondary"
                    type="button"
                  >
                    <feather-icon icon="SendIcon" />
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
  },
  data() {
    return {};
  },
  methods: {
    moment(v) {
      return moment(v);
    },
    convertDate(val) {
      let result = null;
      if (val != null) {
        result = utils.moment(val).format("DD.MM.YYYY HH:mm");
      }
      return result;
    },
    openLocation() {
      window.open(this.sf.delivery.location, "_blank");
    },
  },
  created() {},
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    task_status() {
      let result = [];
      result.push({ label: "Tümü", value: "" });
      result.push(...this.global.task_status);
      return result;
    },
    task_types() {
      let result = [];
      result.push({ label: "Tümü", value: "" });
      result.push(...this.global.task_types);
      return result;
    },
    getTaskType() {
      let result = null;
      result = utils.getTaskTypes(this.sf.task.task_type).label;
      return result;
    },
    task_todo() {
      let result = [];
      result.push({ label: "Tümü", value: "" });
      result.push(...this.global.task_todo);
      return result;
    },
    list_service_task() {
      let result = [];
      return result;
    },
    delivery_type() {
      let result = utils.getDeliveryTypes(this.sf.delivery.type).label;
      return result;
    },
  },
};
</script>